<template>
    <div class="dashboard">
        <div class="row margin-0">
            <div class="col-md-12 pad-15">
                <div class="parent margin-bottom-5">
                    <div class="parent-body">
                        <div class="row margin-0">
                            <div class="col pad-0 text-center">
                                <h1>{{stats.products || 0}}</h1>
                                <h5 class="txt-grey">Total Products</h5>
                            </div>
                            <div class="col pad-0 text-center">
                                <h1>{{stats.active_products || 0}}</h1>
                                <h5 class="txt-grey">Active Products</h5>
                            </div>
                            <div class="col pad-0 text-center">
                                <h1>{{stats.featured || 0}}</h1>
                                <h5 class="txt-grey">Featured Products</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row margin-0">
                    <div class="col pad-0 text-center">
                        <div class="parent margin-5">
                            <div class="parent-body">
                                <h1>{{stats.users || 0}}</h1>
                                <h5 class="txt-grey">Total Users</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col pad-0 text-center">
                        <div class="parent margin-5">
                            <div class="parent-body">
                                <h1>{{stats.customers || 0}}</h1>
                                <h5 class="txt-grey">Active Customers</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col pad-0 text-center">
                        <div class="parent margin-5">
                            <div class="parent-body">
                                <h1>{{stats.business || 0}}</h1>
                                <h5 class="txt-grey">Active Business</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row margin-0" v-if="stats && stats.action_list">
                    <div class="col-6 pad-5">
                        <div class="parent margin-bottom-5">
                            <div class="parent-body">
                                <h5 class="bold margin-bottom-10"><b>Request &amp; Actions</b></h5>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Action</th>
                                        <th>OPEN</th>
                                        <th>INPROGRESS</th>
                                        <th>COMPLETED</th>
                                        <th>TOTAL</th>
                                    </tr>
                                    <template v-for="(item, idx) in stats.action_list" :key="idx">
                                        <tr>
                                            <td>{{item.action}}</td>
                                            <td>{{item.OPEN}}</td>
                                            <td>{{item.INPROGRESS}}</td>
                                            <td>{{item.COMPLETED}}</td>
                                            <td>{{item.TOTAL}}</td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 pad-5" v-if="financials">
                        <div class="parent margin-bottom-5">
                            <div class="parent-body">
                                <h5 class="bold margin-bottom-10"><b>Transactions</b></h5>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Modules</th>
                                        <th>Today</th>
                                        <th>Current Week</th>
                                        <th>Current Month</th>
                                    </tr>
                                    <tr>
                                        <td>Enquiries</td>
                                        <td>{{financials.DAY.ENQUIRES}}</td>
                                        <td>{{financials.WEEK.ENQUIRES}}</td>
                                        <td>{{financials.MONTH.ENQUIRES}}</td>
                                    </tr>
                                    <tr>
                                        <td>Enquiry unlocks</td>
                                        <td>{{financials.DAY.ENQUIRES_UNLOCKS}}</td>
                                        <td>{{financials.WEEK.ENQUIRES_UNLOCKS}}</td>
                                        <td>{{financials.MONTH.ENQUIRES_UNLOCKS}}</td>
                                    </tr>
                                    <tr>
                                        <td>Announcements</td>
                                        <td>{{financials.DAY.ANNOUNCEMENT}}</td>
                                        <td>{{financials.WEEK.ANNOUNCEMENT}}</td>
                                        <td>{{financials.MONTH.ANNOUNCEMENT}}</td>
                                    </tr>
                                    <tr>
                                        <td>Announcement unlocks</td>
                                        <td>{{financials.DAY.ANNOUNCEMENT_UNLOCKS}}</td>
                                        <td>{{financials.WEEK.ANNOUNCEMENT_UNLOCKS}}</td>
                                        <td>{{financials.MONTH.ANNOUNCEMENT_UNLOCKS}}</td>
                                    </tr>
                                    <tr>
                                        <td>Demo Requests</td>
                                        <td>{{financials.DAY.DEMO}}</td>
                                        <td>{{financials.WEEK.DEMO}}</td>
                                        <td>{{financials.MONTH.DEMO}}</td>
                                    </tr>
                                    <tr>
                                        <td>Demo unlocks</td>
                                        <td>{{financials.DAY.DEMO_UNLOCKS}}</td>
                                        <td>{{financials.WEEK.DEMO_UNLOCKS}}</td>
                                        <td>{{financials.MONTH.DEMO_UNLOCKS}}</td>
                                    </tr>
                                    <tr>
                                        <td>Student Enrollments</td>
                                        <td>{{financials.DAY.STUDENT_ENROLLMENTS}}</td>
                                        <td>{{financials.WEEK.STUDENT_ENROLLMENTS}}</td>
                                        <td>{{financials.MONTH.STUDENT_ENROLLMENTS}}</td>
                                    </tr>
                                    <tr>
                                        <td>Teacher Enrollments</td>
                                        <td>{{financials.DAY.TEACHER_ENROLLMENTS}}</td>
                                        <td>{{financials.WEEK.TEACHER_ENROLLMENTS}}</td>
                                        <td>{{financials.MONTH.TEACHER_ENROLLMENTS}}</td>
                                    </tr>
                                    <tr>
                                        <td>Coins Generated</td>
                                        <td>{{financials.DAY.COINS_GENERATED}}</td>
                                        <td>{{financials.WEEK.COINS_GENERATED}}</td>
                                        <td>{{financials.MONTH.COINS_GENERATED}}</td>
                                    </tr>
                                    <tr>
                                        <td>Coins Utilized</td>
                                        <td>{{financials.DAY.COINS_UTILIZED}}</td>
                                        <td>{{financials.WEEK.COINS_UTILIZED}}</td>
                                        <td>{{financials.MONTH.COINS_UTILIZED}}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import VueApexCharts from 'vue-apexcharts'
export default {
    components: {
        // apexcharts: VueApexCharts,
  },
  data() {
    return {
        dataList: {
        results: []
        },
        tab: 'users',
        stats: {},
        users: {},
        processes: null,
        selected_index: 0,
        chartOptions1: {
        chart: {
            id: 'basic-bar'
        },
        xaxis: {
            categories: [[1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]]
        }
        },
        series1: [{
        name: 'Users',
        data: [30, 40, 45, 50, 49, 60, 70, 91, 30, 40, 45, 50, 49, 60, 70, 91]
        }],
        chartOptions: {
        chart: {
            id: 'basic-bar'
        },
        xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
        },
        series: [{
        name: 'Users',
        data: [30, 40, 45, 50, 49, 60, 70, 91, 30, 40, 45, 50, 49, 60, 70, 91]
        }],
        radialBarseries: [44, 55, 67, 99],
        radialBarchartOptions: {
        plotOptions: {
        radialBar: {
            dataLabels: {
            name: {
                fontSize: '22px',
            },
            value: {
                fontSize: '16px',
            },
            total: {
                show: true,
                label: 'Users',
                formatter: function (w) {
                    return 100
                }
            }
            }
        }
        },
        labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
        },
        process_subs: false,
        process_events: false,
    };
  },
  created() {
      this.getStats()
    //   this.getUsersList()
    //   this.getStatistics()
  },
  computed: {
    financials: function() {
        if(this.stats && this.stats.DAY) {
            return this.stats;
        }
        return null;
    }
  },
  methods: {
      getStats() {
          this.$cs.admin.list({})
          .then(res => {
              this.stats = res.data
          })
      },
      getStatistics() {
          this.$cs.admin.list({})
          .then(res => {
              this.processes = res.data
              this.processStats()
          })
      },
      validateSubscriptions() {
          const that = this;
          that.process_subs = true;
          that.$cs.validate.create({
              data: {}
          })
          .then(res => {
              that.process_subs = false;
              alert(res.data);
          })
      },
      validatePayments() {
          const that = this;
          that.process_events = true;
          that.$cs.eventpayment.list({
              resource: `${that.$cs.eventpayment.api}validate/`
          })
          .then(res => {
              that.process_events = false;
              alert(res.data);
          })
      },
      processStats() {

      },
      getUsersList(page=null) {
        const that = this;
        let params = {
            sort: 'user_id',
            status: 'submitted'
        }
        that.$cs.user
            .list({
                resource: page,
                pageSize: 10000,
                page,
                params 
            })
            .then(
            data => {
                that.users = Object.assign(data.data);
            },
            () => {
                console.log("error");
            }
        );
    },
  }
}
</script>
<style scoped>
</style>
